import { render, staticRenderFns } from "./MedicalVR.vue?vue&type=template&id=d5a901de&scoped=true&"
import script from "./MedicalVR.vue?vue&type=script&lang=js&"
export * from "./MedicalVR.vue?vue&type=script&lang=js&"
import style0 from "./MedicalVR.vue?vue&type=style&index=0&id=d5a901de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5a901de",
  null
  
)

export default component.exports