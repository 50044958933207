<template>
  <div class="similar">

    <div class="prev-next line1" v-if="dataSlider.linkShow">

      <router-link v-bind:class="[{ hide: dataSlider.prevLink ==='' }]" :to="{path:dataSlider.prevLink}" class="prev-next__to-page cursor-hover back">
        <div class="prev-next__arrow">
          <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6953 1.32227L1.00787 12.01L11.7049 22.7071" stroke="black" stroke-linecap="round"/>
            <path d="M1.4555 11.973L24.3965 11.9732" stroke="black" stroke-linecap="round"/>
          </svg>
        </div>
        <p class="prev-next__name">{{ $t('prev-btn') }}</p>
      </router-link>

      <div class="prev-next__sep"></div>

      <router-link v-bind:class="[{ hide: dataSlider.nextLink==='' }]" :to="{path:dataSlider.nextLink}" class="prev-next__to-page cursor-hover to">
        <p class="prev-next__name">{{ $t('next-btn') }}</p>
        <div class="prev-next__arrow">
          <svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1859 1.32227L24.8733 12.01L14.1762 22.7071" stroke="black" stroke-linecap="round"/>
            <path d="M24.4257 11.973L1.48469 11.9732" stroke="black" stroke-linecap="round"/>
          </svg>
        </div>
      </router-link>
    </div>

    <div class="title line2" v-bind:class="[{ padding: dataSlider.linkShow }]">
      <div class="title__text" v-html="$t(dataSlider.title)"></div>
<!--      <div class="title__icon">-->
<!--        <svg viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--            d="M55.9961 31.3438L56.4961 31.3438L56.4961 30.8438L55.9961 30.8438L55.9961 31.3438ZM55.9961 51.1971H55.4961L55.4961 52.4042L56.3496 51.5506L55.9961 51.1971ZM47.0409 31.3438L47.0409 30.8438L46.5409 30.8438L46.5409 31.3438L47.0409 31.3438ZM47.0409 51.1917L46.6873 51.5453L47.5409 52.3989L47.5409 51.1917H47.0409ZM39.8543 44.0052L40.2079 43.6517L39.8543 43.2981L39.5008 43.6517L39.8543 44.0052ZM33.522 50.3375L33.1685 49.984L32.8149 50.3375L33.1685 50.6911L33.522 50.3375ZM51.5211 68.3366L51.1676 68.6902L51.5211 69.0437L51.8747 68.6902L51.5211 68.3366ZM70.3086 49.5492L70.6621 49.9027L71.0157 49.5492L70.6621 49.1956L70.3086 49.5492ZM63.9763 43.2169L64.3298 42.8633L63.9763 42.5098L63.6227 42.8633L63.9763 43.2169ZM55.4961 31.3438L55.4961 51.1971H56.4961L56.4961 31.3438L55.4961 31.3438ZM47.0409 31.8438H55.9961L55.9961 30.8438H47.0409L47.0409 31.8438ZM47.5409 51.1917L47.5409 31.3438L46.5409 31.3438L46.5409 51.1917H47.5409ZM39.5008 44.3588L46.6873 51.5453L47.3944 50.8382L40.2079 43.6517L39.5008 44.3588ZM33.8756 50.6911L40.2079 44.3588L39.5008 43.6517L33.1685 49.984L33.8756 50.6911ZM51.8747 67.9831L33.8756 49.984L33.1685 50.6911L51.1676 68.6902L51.8747 67.9831ZM69.955 49.1956L51.1676 67.9831L51.8747 68.6902L70.6621 49.9027L69.955 49.1956ZM63.6227 43.5704L69.955 49.9027L70.6621 49.1956L64.3298 42.8633L63.6227 43.5704ZM56.3496 51.5506L64.3298 43.5704L63.6227 42.8633L55.6425 50.8435L56.3496 51.5506Z"-->
<!--            fill="black"/>-->
<!--          <circle cx="52" cy="50" r="49.5" stroke="black"/>-->
<!--        </svg>-->
<!--      </div>-->
    </div>

    <div class="slider line3">

      <div :class="`slider__desk slide${slider.length}`">

        <router-link :to="{path: $t(key).link}" class="slider__desk-item cursor-hover" v-for="(key,value) in slider" :key="value">
          <div class="slider__desk-title top" :style="heightTop" ref="topLine" v-html="key.top"></div>
          <div class="slider__desk-img">
            <img :src="require(`../assets/img/simular-img/${key.img}`)" alt="">
          </div>
          <div class="slider__desk-title bottom" v-html="key.bottom"></div>
        </router-link>

      </div>

      <div class="slider__mobile">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(key,value) in slider" :key="value">
            <router-link :to="{path: key.link}" class="slider__mobile-item cursor-hover">
              <div class="slider__mobile-title top" :style="heightTop" ref="topLine" v-html="key.top"></div>
              <div class="slider__mobile-img">
                <img :src="require(`../assets/img/simular-img/${key.img}`)" alt="">
              </div>
              <div class="slider__mobile-title bottom" v-html="key.bottom"></div>
            </router-link>
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "similar",
  props: {
    dataSlider: Object
  },
  data() {
    return {

      minHeight: '0px',
      slider: [],
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    };
  },

  computed: {
    heightTop() {
      return {
        "min-height": this.minHeight,
      };
    }
  },

  mounted() {

    setTimeout(() => {
      this.setHeight()
    }, 100)

    window.addEventListener('resize', () => {

      this.minHeight = '0px'

      setTimeout(() => {
        this.setHeight()
      })
    });

    this.dataSlider.slider.forEach((el) => {
      this.slider.push(this.$t(el))
    })
  },
  methods: {
    setHeight() {
      let values = []
      this.$refs.topLine.forEach((el) => {
        values.push(el.offsetHeight)
      })

      let value = Math.max.apply(Math, values.map((o) => {
        return o;
      }))

      this.minHeight = value + 'px';
    }
  }
}
</script>

<style scoped lang="scss">
.similar {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: calc(1140px + 90px);

  margin: 0 auto;

  padding: 60px 45px 72px 45px;

  @media screen and (max-width: 768px) {
    padding: 49px 45px 56px 45px;
  }

  @media screen and (max-width: 425px) {
    padding: 48px 20px 82px 20px;
  }

  .prev-next {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;

    @media screen and(max-width: 425px) {
      margin: 0 auto 0 auto;
    }

    &__arrow {

      width: 23px;

      svg {
        width: 100%;
        height: auto;
        display: block;

        path {
          transition: all .2s ease;
          stroke: var(--text-primary-color);
        }
      }
    }

    &__name {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;

      letter-spacing: 0.04em;

      color: var(--text-primary-color);
      transition: all .2s ease;
    }

    &__to-page {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100px;

      @media screen and (min-width: 1023px) {
        &:not(.hide) {

          &:hover {

            svg {
              path {
                stroke: #29F6D9;

              }
            }
          }
        }
      }

      &:not(.hide) {

        &:active {

          svg {
            path {
              stroke: #29F6D9;

            }
          }
        }
      }

      &.hide {
        opacity: .3;
      }

      &.back {
        .prev-next__arrow {
          margin-right: 15px;
        }
      }

      &.to {
        .prev-next__arrow {
          margin-left: 15px;
        }
      }
    }

    &__sep {
      margin: 0 20px;
      height: 28px;
      width: 1px;
      background: var(--text-primary-color);
    }
  }

  .title {
    display: flex;
    align-items: center;
    padding: 0 0 32px 0;

    &.padding {

      padding: 100px 0 32px 0;
    }

    &__text {
      text-align: left;
      font-family: "TimesNewRoman-I";
      font-style: italic;
      font-weight: 400;
      font-size: 80px;
      line-height: 80px;
      color: var(--text-primary-color-case);

      @media screen and (max-width: 768px) {
        font-size: 44px;
        line-height: 44px;
      }
    }

    &__icon {

      max-width: 100px;
      width: 100%;
      margin-left: 12px;

      @media screen and (max-width: 500px) {
        max-width: 64px;
      }

      svg {
        width: 100%;
        height: auto;
        display: block;

        path {
          fill: var(--text-primary-color);
        }

        circle {
          stroke: var(--text-primary-color);
        }
      }
    }
  }

  .slider {
    &__desk {

      display: flex;

      @media screen and (max-width: 768px) {
        flex-flow: row wrap;
        margin: 0 auto;
      }

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &__desk-item {
      max-width: calc(25% + 4px);
      width: 100%;

      padding: 20px 24px;

      transition: all .2s ease;
      border: 1px solid #92A1AE;

      margin-right: -1px;

      position: relative;
      z-index: 1;

      @media screen and(max-width: 768px) {
        max-width: 50%;
        margin-bottom: -1px;
      }

      @media screen and (min-width: 1023px) {
        &:hover {
          border-color: var(--text-primary-color-case) !important;
          z-index: 2;

          .slider__desk-img {
            img {
              transform: scale(1.1);
            }


            &:before {
              opacity: 1;
            }
          }
        }
      }

      &:active {
        border-color: var(--text-primary-color-case) !important;
        z-index: 2;

        .slider__desk-img {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

    &__desk-title {
      text-align: left;

      &.top {
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;

        letter-spacing: 0.06em;
        text-transform: uppercase;

        color: var(--text-secondary-color);

        opacity: 0.8;
        margin-bottom: 8px;
      }

      &.bottom {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        letter-spacing: 0.02em;

        color: var(--text-primary-color);
        margin-top: 12px;

      }
    }

    &__desk-img {
      position: relative;
      transition: all .2s ease;
      overflow: hidden;

      &:before {
        transition: all .2s ease;
        opacity: 0;
        content: 'View';
        position: absolute;
        z-index: 2;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 80px;
        height: 80px;
        background: #29F6D9;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: 'Times New Roman';
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;

        letter-spacing: 0.04em;

        color: #000000;
      }

      img {
        transition: all .2s ease;

        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__mobile {
      display: none;
      @media screen and (max-width: 500px) {
        display: block;
      }
    }

    &__mobile-title {
      text-align: left;

      &.top {
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;

        letter-spacing: 0.06em;
        text-transform: uppercase;
        min-height: 28px;

        color: var(--text-secondary-color);

        opacity: 0.8;
        margin-bottom: 8px;
      }

      &.bottom {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        letter-spacing: 0.02em;

        color: var(--text-primary-color);
        margin-top: 12px;

      }
    }

    &__mobile-img {
      overflow: hidden;

      &:before {
        transition: all .2s ease;
        opacity: 0;
        content: 'View';
        position: absolute;
        z-index: 2;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 80px;
        height: 80px;
        background: #29F6D9;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: 'Times New Roman';
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;

        letter-spacing: 0.04em;

        color: #000000;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
        transition: all .2s ease;
      }
    }

    ::v-deep .swiper-container {
      padding-bottom: 28px;

      .swiper-pagination-bullet {
        background: var(--decor-line-color);

        &.swiper-pagination-bullet-active {
          background: var(--menu-icon-color);
        }
      }
    }

    ::v-deep .swiper-pagination {
      bottom: 0;
    }

    ::v-deep.swiper-slide {
      width: calc(80% - 3px);

      border: 1px solid #92A1AE;
      margin-right: -1px;
      padding: 20px 24px;

      &:last-child {
        margin-right: 0;
      }

      height: auto;

      display: flex;

      &:active {
        border-color: var(--text-primary-color-case) !important;
        z-index: 2;

        .slider__mobile-img {

          img {
            transform: scale(1.1);
          }
        }
      }

      &:last-child {
        border-right: 1px solid #92A1AE;
      }
    }
  }
}

</style>

